import React from 'react'
import { Image } from '@shared/components/image'
import { Section } from '@shared/components/section'
import { useScreenSize } from '@shared/hooks/use-screen-size'
import cx from 'classnames'
import { JournalPoint, journalPointsDefalut } from 'screens/about/sections/journal/data'

import styles from './style.module.scss'

interface Props {
	sectionClassName?: string
	journalPoints?: JournalPoint[]
}

export const View = ({ sectionClassName, journalPoints = journalPointsDefalut }: Props) => {
	const { isExtraMobile } = useScreenSize()

	return (
		<Section className={cx({ [styles.wrapper]: !sectionClassName }, sectionClassName)}>
			<h2>
				Журнал «Процент» <br /> про кредиты
			</h2>
			<div className={styles.content}>
				<div className={styles.summary}>
					<h3 className={styles.summarySubtitle}>
						Вместе с экспертами рассказываем, <br /> как экономить на кредитах
					</h3>
					<div className={styles.summaryContent}>
						Наша цель — помочь людям не потерять свои деньги из-за кредитных рисков. Для этого мы
						пишем статьи и снимаем видео, в которых эксперты рассказывают о том, как избежать
						штрафов, скрытых условий и уловок мошенников.
					</div>
				</div>
				<div className={styles.links}>
					{journalPoints.map(({ href, imageUrl, imageUrlMobile, content, imageClassName }, i) => (
						<a
							className={cx(styles.link, 'main-link')}
							key={`journal-link-${i}`}
							href={href}
							target='_blank'
							rel='noopener noreferrer'
						>
							<div className={cx({ [styles.img]: !imageClassName }, imageClassName)}>
								<Image name={isExtraMobile && imageUrlMobile ? imageUrlMobile : imageUrl} lazy />
							</div>
							<h4 className={styles.journalPointTitle}>{content}</h4>
						</a>
					))}
				</div>
			</div>
		</Section>
	)
}
