import React from 'react'
import { Button } from '@shared/components/button'

import { ComfortTerm } from './model'

import styles from './style.module.scss'

interface Props {
	terms: ComfortTerm[]
	handleClick: () => void
}

export const View = ({ terms, handleClick }: Props) => {
	return (
		<div className={styles.wrapper}>
			<h2 className={styles.title}>Комфортные условия</h2>
			<ul className={styles.terms}>
				{terms.map((term) => {
					return (
						<li key={term.id}>
							{term.icon}
							<span>{term.title}</span>
						</li>
					)
				})}
			</ul>
			<Button size='m' className={styles.button} onClick={handleClick}>
				Оставить заявку
			</Button>
		</div>
	)
}
