import React, { useMemo, useState } from 'react'
import { usePageSettings } from '@providers/page-settings'
import { Button } from '@shared/components/button'

import { ProductCard } from './ProductCard'

import styles from './style.module.scss'

const SHORT_MODE_LEN = 10

export const View = () => {
	const settings = usePageSettings()

	const [isExpanded, setIsExpanded] = useState(false)
	const promoPages = useMemo(() => {
		const data = settings?.admin?.promoPages || []
		const results = [...data].sort((a, b) => a.order - b.order)

		if (data.length <= SHORT_MODE_LEN) {
			setIsExpanded(true)
		}

		if (isExpanded) {
			return results
		}

		return results.slice(0, SHORT_MODE_LEN)
	}, [settings, isExpanded])

	return (
		<div className={styles.wrapper}>
			<h2 className={styles.title}>Кредиты на любые цели</h2>
			<div className={styles.products}>
				{promoPages.map((lp) => {
					return <ProductCard key={lp.id} {...lp} />
				})}
			</div>
			{!isExpanded && (
				<Button
					onClick={() => setIsExpanded(true)}
					variant='outlined'
					size='m'
					className={styles.button}
				>
					Показать ещё
				</Button>
			)}
		</div>
	)
}
