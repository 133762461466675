import React, { useRef, useState } from 'react'
import VideoPlay from '@shared/svg/video-play.svg'
import cx from 'classnames'

import { Props } from './model'

import styles from './style.module.scss'

export const View = ({ src, poster, className }: Props) => {
	const [isPlay, setPlay] = useState(false)
	const videoRef = useRef() as React.MutableRefObject<HTMLVideoElement>

	return (
		<button className={cx(styles.wrapper, className)} onClick={() => setPlay(true)}>
			<video src={src} controls={isPlay} className={styles.video} poster={poster} ref={videoRef}>
				<source src={src} type='video/mp4' />
			</video>
			{!isPlay && <VideoPlay className={styles.playIcon} onClick={() => videoRef.current.play()} />}
		</button>
	)
}
