import React from 'react'
import { Container } from '@shared/components/container'
import { Image } from '@shared/components/image'
import { AppContent } from '@shared/components/sections/app'
import { useSelectDevice } from '@shared/hooks/use-select-device'

import styles from './style.module.scss'

export const View = () => (
	<section className={styles.wrapper}>
		<Container>
			<div className={styles.app}>
				<AppContent withLogo headerStyles={styles.header} />
			</div>
			<div className={styles.img}>
				<Image
					name={useSelectDevice({
						largeDesktop: 'Repose-Isometric-iPhone-12-xl.png',
						default: 'Repose-Isometric-iPhone-12.png',
					})}
					alt='iphone'
				/>
			</div>
		</Container>
	</section>
)
