import React, { Suspense } from 'react'
import dynamic from 'next/dynamic'

import { TabsNames } from '../../model'
import { TabLoan } from '../tab-loan'

interface Props {
	activeTab: TabsNames
}

const DynamicEconomy = dynamic(() => import('../tab-economy').then((mod) => mod.TabEconomy))

const DynamicTechnology = dynamic(() =>
	import('../tab-technology').then((mod) => mod.TabTechnology)
)

export const View = ({ activeTab }: Props) => {
	switch (activeTab) {
		case 'loan':
			return <TabLoan />
		case 'economy':
			return (
				<Suspense fallback='Loading...'>
					<DynamicEconomy />
				</Suspense>
			)
		case 'technology':
			return (
				<Suspense fallback='Loading...'>
					<DynamicTechnology />
				</Suspense>
			)
	}
}
