export const loanItems = [
	'Под залог квартиры',
	'Под залог авто',
	'Ипотека',
]
export const economyItems = [
	'Консервативный инструмент',
	'Под надзором Банка России',
	'Фиксированная доходность',
]
export const technologyItems = [
	'Автоматизация',
	'Скоринг',
	'Цифровой сервис',
	'Рынок электронных закладных',
]
