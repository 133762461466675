import React from 'react'
import { BlockDisclaimerCentralBank } from '@blocks/BlockDisclaimerCentralBank'
import PageAnimation from '@shared/components/page-animation'
import { MainV2 } from '@shared/components/sections/main-v2'
import { ViewTabs } from '@shared/components/view-tabs'

import styles from './style.module.scss'

export type MainPaveV2Props = {
	rootH1Text: string
}

export const View = ({ rootH1Text }: MainPaveV2Props = {} as MainPaveV2Props) => {
	return (
		<PageAnimation>
			<div className={styles.sectionMain}>
				<MainV2 h1={rootH1Text} />
			</div>
			<BlockDisclaimerCentralBank />
			<ViewTabs />
		</PageAnimation>
	)
}
