import React, { ReactNode } from 'react'
import { Button } from '@shared/components/button'
import { Section } from '@shared/components/section'
import { ISelectObject, useSelectDevice } from '@shared/hooks/use-select-device'
import scrollToElement from '@shared/pipes/scroll'
import FullLogoWhite from '@shared/svg/full-logo-white.svg'
import kvTablet1024 from '../../../../../public/images/webp/kv-tablet-1024.webp'
import kvMobile540 from '../../../../../public/images/webp/kv-mobile-540.webp'
import MainMaskMobile from '../../../../../public/images/webp/MainMaskMobile.webp'
import MainMaskTablet from '../../../../../public/images/webp/MainMaskTablet.webp'
import MainMaskDesktop from '../../../../../public/images/webp/MainMaskDesktop.webp'
import MainMaskLargeDesktop from '../../../../../public/images/webp/MainMaskLargeDesktop.webp'
import styles from './style.module.scss'
import Image, { type StaticImageData } from 'next/image'

const texts: ReactNode[] = [
	'Без страховок и скрытых комиссий',
	'Любая кредитная история',
	<>Быстрое решение</>,
]

const image: { size: ISelectObject<StaticImageData>; imageAlt: string } = {
	size: {
		extraMobile: kvMobile540,
		mobile: kvMobile540,
		tablet: kvTablet1024,
		desktop: kvTablet1024,
		largeDesktop: kvTablet1024,
		default: kvMobile540,
	},
	imageAlt: 'Кредит на любые цели, Credit.Club',
}

const wrapImage: { size: ISelectObject<StaticImageData>; imageAlt: string } = {
	size: {
		mobile: MainMaskMobile,
		tablet: MainMaskTablet,
		desktop: MainMaskDesktop,
		largeDesktop: MainMaskLargeDesktop,
		default: MainMaskMobile,
	},
	imageAlt: 'Кредит на любые цели, Credit.Club',
}

export const View = ({ h1 }: { h1: string }) => {
	const handleClick = () => {
		scrollToElement(`[data-id="form-version-2"]`, {
			align: {
				top: 0,
				topOffset: 100,
			},
		})
	}

	return (
		<div className={styles.outerWrap}>
			<Image
				priority
				className={styles.wrapImage}
				alt={wrapImage.imageAlt}
				src={useSelectDevice(wrapImage.size)}
			/>
			<Section className={styles.innerWrap}>
				<div className={styles.imageWrap}>
					<div className={styles.logo}>
						<FullLogoWhite />
					</div>
					<Image
						priority
						src={useSelectDevice(image.size)}
						alt={image.imageAlt}
					/>
				</div>
				<h1 className={styles.title}>{h1}</h1>
				<ul className={styles.descriptionWrap}>
					{texts.map((text, idx) => (
						<li key={idx}>{text}</li>
					))}
				</ul>
				<Button onClick={handleClick} className={styles.button}>
					Оставить заявку
				</Button>
			</Section>
		</div>
	)
}
