import React, { useMemo } from 'react'
import Slider from 'react-slick'
import { Section } from '@shared/components/section'
import { ScreensizeParams, useScreenSize } from '@shared/hooks/use-screen-size'
import { Paths } from '@shared/menu-items/paths'
import scrollToElement from '@shared/pipes/scroll'
import cx from 'classnames'
import Link from 'next/link'

import { TabsNames } from '../../model'

import { economyItems, loanItems, technologyItems } from './data'

import CardsTech from '../../../../../../public/images/webp/CardsTech.webp'
import CardsTechActive from '../../../../../../public/images/webp/CardsTech-active.webp'
import MobileCardsTech from '../../../../../../public/images/webp/MobileCardsTech.webp'
import MobileCardsTechActive from '../../../../../../public/images/webp/MobileCardsTech-active.webp'

import CardLoan from '../../../../../../public/images/webp/CardLoan.webp'
import CardLoanActive from '../../../../../../public/images/webp/CardLoan-active.webp'
import MobileCardLoan from '../../../../../../public/images/webp/MobileCardLoan.webp'
import MobileCardLoanActive from '../../../../../../public/images/webp/MobileCardLoan-active.webp'

import CardsSavings from '../../../../../../public/images/webp/CardsSavings.webp'
import CardsSavingsActive from '../../../../../../public/images/webp/CardsSavings-active.webp'
import MobileCardsSavings from '../../../../../../public/images/webp/MobileCardsSavings.webp'
import MobileCardsSavingsActive from '../../../../../../public/images/webp/MobileCardsSavings-active.webp'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import styles from './style.module.scss'
import { useSelectDevice } from '@shared/hooks/use-select-device'
import Image from 'next/image'

interface Props {
	activeTab: TabsNames
	setActiveTab: (tabName: TabsNames) => void
}

const getSLidesToShow = (screenSize: ScreensizeParams) => {
	if (screenSize.isExtraMobile) return 1
	if (screenSize.isMobile) return 1
	if (screenSize.isTablet) return 2
	if (screenSize.isDesktop) return 3
	if (screenSize.isLargeDesktop) return 3

	return 2
}

export const View = ({ activeTab, setActiveTab }: Props) => {
	const screenSize = useScreenSize()
	const handleTabSwitch = (tabName: TabsNames) => {
		setActiveTab(tabName)
		scrollToElement(`[data-id="content"]`, {
			align: {
				top: 0,
				topOffset: 100,
			},
		})
	}

	const settings = useMemo(
		() => ({
			className: styles.slider,
			dots: false,
			infinite: false,
			speed: 500,
			slidesToShow: getSLidesToShow(screenSize),
			slidesToScroll: 1,
			arrows: false,
			centerMode: screenSize.isMobile || screenSize.isExtraMobile,
			centerPadding: '5%',
		}),
		[screenSize]
	)

	return (
		<>
			<Section fullWidth className={styles.section}>
				<Slider {...settings}>
					<div>
						<div
							className={cx(styles.controller, styles.loan, {
								[styles.active]: activeTab === 'loan',
							})}
							onClick={() => handleTabSwitch('loan')}
						>
							<Image
								alt=''
								src={useSelectDevice({
									largeDesktop: activeTab === 'loan' ? CardLoanActive : CardLoan,
									desktop: activeTab === 'loan' ? CardLoanActive : CardLoan,
									default: activeTab === 'loan' ? MobileCardLoanActive : MobileCardLoan,
									extraMobile: activeTab === 'loan' ? MobileCardLoanActive : MobileCardLoan,
									mobile: activeTab === 'loan' ? MobileCardLoanActive : MobileCardLoan,
								})}
							/>
							<h2 className={styles.title}>Кредиты</h2>
							<div className={styles.desc}>по низким ставкам</div>
							<ul className={styles.items}>
								{loanItems.map((loan, idx) => {
									return <li key={idx}>{loan}</li>
								})}
							</ul>
						</div>
					</div>
					<div>
						<Link href={Paths.invest}>
							<div
								className={cx(styles.controller, styles.economy, {
									[styles.active]: activeTab === 'economy',
								})}
							>
								<Image
									alt=''
									src={useSelectDevice({
										largeDesktop: activeTab === 'economy' ? CardsSavingsActive : CardsSavings,
										desktop: activeTab === 'economy' ? CardsSavingsActive : CardsSavings,
										default: activeTab === 'economy' ? MobileCardsSavingsActive : MobileCardsSavings,
										extraMobile:
											activeTab === 'economy' ? MobileCardsSavingsActive : MobileCardsSavings,
										mobile: activeTab === 'economy' ? MobileCardsSavingsActive : MobileCardsSavings,
									})}
								/>
								<h2 className={styles.title}>Инвестиции</h2>
								<div className={styles.desc}>с выгодной доходностью</div>
								<ul className={styles.items}>
									{economyItems.map((loan, idx) => {
										return <li key={idx}>{loan}</li>
									})}
								</ul>
							</div>
						</Link>
					</div>
					<div>
						<div
							className={cx(styles.controller, styles.technology, {
								[styles.active]: activeTab === 'technology',
							})}
							onClick={() => handleTabSwitch('technology')}
						>
							<Image
								alt=''
								src={useSelectDevice({
									largeDesktop: activeTab === 'technology' ? CardsTechActive : CardsTech,
									desktop: activeTab === 'technology' ? CardsTechActive : CardsTech,
									default: activeTab === 'technology' ? MobileCardsTechActive : MobileCardsTech,
									extraMobile: activeTab === 'technology' ? MobileCardsTechActive : MobileCardsTech,
									mobile: activeTab === 'technology' ? MobileCardsTechActive : MobileCardsTech,
								})}
							/>
							<h2 className={styles.title}>Технологии</h2>
							<div className={styles.desc}>
								для финансовых организаций <br /> и граждан
							</div>
							<ul className={styles.items}>
								{technologyItems.map((loan, idx) => {
									return <li key={idx}>{loan}</li>
								})}
							</ul>
						</div>
					</div>
				</Slider>
			</Section>
			<div data-id='content' />
		</>
	)
}
