import React from 'react'
import SquareArrow from '@shared/svg/SquareArrow.svg'

import { PromoPage } from '../../../../admin/types'

import styles from './style.module.scss'
import Image from 'next/image'

export const ProductCard = ({ title, previewUrl, url, hexColor }: PromoPage) => {
	return (
		<a className={styles.card} href={url} target='_blank' rel='noopener noreferrer'>
			<div className={styles.name}>{title}</div>
			<Image alt={title} className={styles.image} loading='lazy' fill src={previewUrl} />
			<SquareArrow />
			<div style={{ background: hexColor }} className={styles.overlay} />
		</a>
	)
}
